<template>
  
  <div>
    <va-card v-if="$store.state.app.isLoading">
        <div class="text-center">
         <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        </div>
        <va-progress-bar
          :value="bufferValues.value"
          :buffer="bufferValues.buffer"
        >
          <p class="py-2">Loading Patient Statistics, <b style="color: rgb(85, 25, 25);">This may take a few minutes</b></p>
        </va-progress-bar>
    </va-card>
    <div
      class="grid row mx-auto"
      v-else
    >
      <div
        class="flex xs12 md12"
      >
        <div class="flex xs12 md12">
          <div class="row">     
            <model-selection
              :models="computedModels"
              :answered-only="answeredOnlySelection"
              @selectedModel="changeModel"
              class="flex xs12 md12 lg6 xl4 text-center ml-auto"
            />
            <model-filter
              @updateInput="changeInputAnswer"
              @updateTimes="changeSelectedTimes"
              @updateFirstTime="changeFirstTime"
              class="flex xs12 md12 lg6 xl4 mr-auto"
            />
          </div>
        </div>

        <div
          class="flex xs12 md12"
        >
          <div class="row">
            <div
              class="flex xs6 md6 lg4 mx-auto"
            >
              <model-card
                :model="model"
                class="mb-2"
              />

              <model-information
                :model="model"
              />
            </div>
            <div
              class="flex xs6 md6 lg8 mx-auto"
            >
              <question-table
                :i18n="questions_i18n"
                :model="model"
              />
            </div>
          </div>
        </div>

        <question-bar-chart
            :i18n="questions_i18n"
            :model="model" />
      </div>
    </div>
  </div>
</template>

<script>
import QuestionBarChart from './question-bar-chart.vue';
import QuestionTable from './question-table.vue';
import ModelCard from './model-card.vue';
import ModelInformation from './model-information.vue';
import ModelSelection from './model-selection.vue';
import ModelFilter from './model-filter.vue';
import _PatientStatistics from '../../class/PatientStatistics'
import axios from '@/scripts/interceptor.js'

export default {
  name: 'PatientStatistics',
  data: function() {
    return {
      questions_i18n: {},
      bufferValues: {
        value: 0,
        buffer: 0,
      },
      index: 0,
      debug: false,
      answeredOnlySelection: true,
      answeredTimeSelection: {
        all: {
          value: true,
        },
        t1: {
          value: false,
        },
        t2: {
          value: false,
        },
        t3: {
          value: false,
        },
        t4: {
          value: false,
        },
      },
      usersResponded: [],
      firstTimeCheck: true,
      models: {},
      models_firstOnly: {},
      model: {},
    };
  },
  components: {
    QuestionBarChart,
    QuestionTable,
    ModelCard,
    ModelInformation,
    ModelSelection,
    ModelFilter,
  },
  async created() {
    this.$store.commit('setLoading', true);
    await this.getI18n();
    await this.getPatientResponseData();
    this.dataResponseSwitch();
  },
  methods: {
    async getI18n(){
      try {
          this.bufferValues.buffer += 15;
          this.bufferValues.value += 10;
          const response = await axios.get('api/patients/translation/il8n');
          const data = response.data;
          this.questions_i18n = data.language;
          this.bufferValues.buffer += 25;
          this.bufferValues.value += 15;
      } catch (error) {
          console.error(error);
      }
    },
    async getPatientResponseData() {
      // Change this out for all exams
      this.bufferValues.buffer += 55;
      this.bufferValues.value += 50;
      try {
          const response = await axios.get('api/patients/retrieve/latest');
          const data = response.data;
          this.models = data.models;
          this.models_firstOnly = data.models_firstOnly;
          this.startingModel(this.models);
          this.startingModel(this.models_firstOnly);
          this.bufferValues.buffer += 10;
          this.bufferValues.value += 10;
          this.$store.commit('setLoading', false);
      } catch (error) {
          console.error(error);
      }
    },
    changeModel(target) {
      this.model = target;
    },
    changeInputAnswer(target) {
      this.answeredOnlySelection = target;
    },
    changeSelectedTimes(target) {
      this.answeredTimeSelection = target;
      this.dataResponseSwitch();
    },
    changeFirstTime(target) {
      this.firstTimeCheck = target;
    },
    startingModel(models) {
      for (const model in models) {
        if (this.models[model].questions[0].totalResponses.all > 0) {
          this.model = models[model];
          return;
        }
      }
    },
    copyObject(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    dataResponseSwitch() {
      // If 'all' is selected, clear and set selectedTimeGroups to ['all']
      if (this.answeredTimeSelection.all.value) {
        this.model.selectedTimeGroups = ['all'];
        return;
      }
      // Otherwise remove 'all' from selectedDate Groups if present
      this.model.selectedTimeGroups = this.model.selectedTimeGroups.filter(timeGroup => timeGroup !== 'all');
      // For each item in answeredTimeSelection, add or remove from selectedTimeGroups as needed
      for (const selectedTime in this.answeredTimeSelection) {
        if (selectedTime === 'all') continue;
        const isSelected = this.answeredTimeSelection[selectedTime].value;
        const isAlreadyIncluded = this.model.selectedTimeGroups.includes(selectedTime);
        if (isSelected && !isAlreadyIncluded) {
          this.model.selectedTimeGroups.push(selectedTime);
        } else if (!isSelected && isAlreadyIncluded) {
          this.model.selectedTimeGroups = this.model.selectedTimeGroups.filter(timeGroup => timeGroup !== selectedTime);
        }
      }
      // If no items are selected, add 'none' to selectedTimeGroups
      if (this.model.selectedTimeGroups.length === 0) {
        this.model.selectedTimeGroups.push('none');
      }
    }
  },

  computed: {
    computedModels() {
      if (this.firstTimeCheck) {
        return this.models_firstOnly;
      } else {
        return this.models;
      }
    },
  },
};
</script>

<style>
  .va-table {
    width: 100%;
  }

</style>
